@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/colors.scss';

h2 {
  @include fonts(50px, 700);
  max-width: 675px;
  line-height: 140%;
  color: $black;
  margin-bottom: 50px;
}

.sectionOne {
  max-width: 1720px;
  margin: 0 auto 100px;
  padding: 0 10px;

  .sectionOnePicWrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 6px;
    padding: 192px 260px 94px;
  }

  h1 {
    @include fonts(74px, 700);
    font-style: normal;
    line-height: 90px;
    color: $black;
    margin-bottom: 3px;
  }

  p {
    @include fonts;
    line-height: 140%;
    color: $black;
    margin-bottom: 50px;
  }
}

.sectionTwo {
  max-width: 1200px;
  margin: 0 auto 220px;
  padding: 0 10px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    @include fonts(18px);
    line-height: 150%;
    color: $main-small-text-color;
    max-width: 586px;
    margin-bottom: 80px;
  }
}

.sectionThree {
  max-width: 1720px;
  margin: 0 auto 172px;
  display: flex;
  position: relative;
  padding: 0 10px;

  img {
    position: sticky;
    top: 50px;
    width: 705px;
    height: 526px;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .sectionThreeDescription {
    max-width: 895px;
    margin-left: 100px;

    h6 {
      @include fonts(38px);
      line-height: 140%;
      color: $main-regular-text-color;
      font-style: normal;
    }

    p {
      @include fonts;
      line-height: 140%;
      color: $main-regular-text-color;
    }
  }
}

.sectionFour {
  max-width: 1720px;
  margin: 0 auto 223px;
  padding: 0 10px;

  .sectionFourPics {
    display: flex;
    justify-content: end;

    img {
      width: 855px;
      height: 638px;
      border-radius: 6px;

      &:first-child {
        max-width: 485px;
        max-height: 362px;
        margin-right: 100px;
      }
    }
  }
}

.sectionFiveDescription,
.sectionFourDescription {
  max-width: 1180px;
  margin: 0 auto;

  h2 {
    margin-bottom: 25px;
  }

  p {
    @include fonts;
    line-height: 30.8px;
    margin-bottom: 60px;
  }
}

.sectionFive {
  max-width: 1720px;
  margin: 0 auto 223px;
  padding: 0 10px;

  .sectionFivePics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      max-width: 596px;
      max-height: 443px;
      border-radius: 6px;

      &:nth-child(odd) {
        max-width: 450px;
        max-height: 335px;
      }
    }
  }
}

.sectionSix {
  max-width: 1720px;
  margin: 0 auto 120px;
  padding: 0 10px;

  .sectionSixPicWrapper {
    @include center;
    flex-direction: row;
    align-items: flex-start;
    background: $main-beauty-business-bg-color;
    padding: 90px 0 137px;
    border-radius: 12px;
  }

  div {
    display: flex;
    flex-direction: column;

    button {
      @include button(59px, $white, $black, $black);
      @include fonts(16px);
      line-height: 19px;
      width: 305px;
    }
  }

  .sectionSixDescription {
    @include fonts(19px);

    h5 {
      line-height: 150%;
      color: $main-primary-color;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-style: normal;
      margin: 25px 0;

      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: calc(-77px - 41px);
        height: 2px;
        width: 77px;
        background: $main-primary-color;
      }
    }

    ul {
      padding-left: 18px;

      li {
        line-height: 23px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
