@import './fonts.scss';
@import './colors.scss';
@import './mixins.scss';

html,
body {
  font-family: 'Proxima Nova', sans-serif;
  color: $black;
}

a {
  color: $main-link-color;

  &:hover {
    color: darken($main-link-color, 10%);
  }
}

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
