@import '../../../../assets/scss/mixins.scss';
@import '../../../../assets/scss/colors.scss';

.mainHeader {
  @include center();
  width: 1200px;
  height: 47px;
  margin: 30px auto;
  padding: 0 10px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .mainHeaderActions {
    @include center;
    gap: 15px;
  }

  .mainOwnerBtn {
    @include button(47px, $main-primary-color, $white, $main-primary-color);
  }

  .logIn {
    @include button(47px, $white, $black, $white);
  }
}
