@import "../../../../../../assets/scss/colors.scss";
@import "../../../../../../assets/scss/mixins.scss";


div.registrationCongratulations {
    padding: 200px 100px 0;
    gap: 30px;
    flex-direction: row;
    flex-wrap: nowrap;

    h2 {
        @include fonts(28px, 700);
        line-height: 140%;
        color: $login-label-text;
        font-style: normal;
        margin-bottom: 10px;
    }

    p {
        @include fonts(16px);
        line-height: 150%;
        color: $login-label-text;
        margin-bottom: 40px;
    }

    button {
        @include button(47px, $white, $black, $black);
    }
}
