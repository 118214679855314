@import '../../../../../../assets/scss/colors.scss';
@import '../../../../../../assets/scss/mixins.scss';

.registerForm {
  .phoneNumber {
    @include border($light-grey, 2px);
    padding: 7px 12px;
    margin-left: 10px;
    transition: border-color 0.3s ease-in-out;
    outline: none;

    &:focus, &:hover, &:active {
      border-color: $login-active-border-color;
    }
  }

  .policyLink {
    @include fonts(12px);
    line-height: 16.8px;
    color: $sign-in-link-color;

    &:hover {
      color: darken($sign-in-link-color, 10);
    }
  }

  .footnote {
    @include fonts(14px);
    color: $dark-grey;

    .personalAccount {
      line-height: 21px;
      color: $black;

      &:hover {
        color: lighten($black, 40);
      }
    }
  }
}

.loginWrapper {
  margin-top: 50px;

  .divider {
    @include fonts(16px);
    line-height: 19px;
    color: $black;
    margin-bottom: 40px;
  }
}

.loginBtnWrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
}

.loginForm, .registerForm {
  label {
    @include fonts(14px);
    line-height: 150%;
    color: $login-label-text;
  }

  input {
    @include fonts(16px);
    line-height: 150%;
  }

  a {
    @include fonts(14px);
    line-height: 150%;
    color: $dark-grey;
    margin-top: 4px;

    &:hover {
      color: darken($dark-grey, 5%);
    }
  }

  button {
    @include button(47px, $white, $black, $black);
    margin-top: 16px;
  }

  .signInPasswordField {
    margin-bottom: 8px;
  }
}
