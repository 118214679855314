@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/mixins.scss';

.sign-in-wrapper {
  padding: 60px 76px 76px;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
    @include fonts(28px, 600);
    color: $sign-in-gray-color;
    line-height: 140%;
    padding: 15px 0;

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $black;
      }
    }
  }

  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: $sign-in-gray-color;
  }

  .ant-tabs-ink-bar {
    background: $black;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $sign-in-gray-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $sign-in-gray-color;
    border-color: $sign-in-gray-color;
  }

  .ant-form label.ant-checkbox-wrapper {
    @include fonts(12px);
  }

  .ant-checkbox-checked::after {
    border-color: $sign-in-gray-color;
  }
}

.sign-in-close {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 36px 36px 0;

  img {
    @include icon(20px);
    cursor: pointer;
  }
}
