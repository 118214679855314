@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/mixins.scss';

.carousel {
  width: 100%;
  position: relative;

  .carousel-button-next {
    right: 0;

    &::after {
      background-image: url('../../../../assets/images/main/arrowRight.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .carousel-button-prev {
    left: 0;

    &::after {
      background-image: url('../../../../assets/images/main/arrowLeft.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .carousel-button-next,
  .carousel-button-prev {
    @include icon(40px);
    position: absolute;
    top: calc(50% - 20px);
    cursor: pointer;
    user-select: none;
    background-color: $arrow-bg;
    border-radius: 50%;

    &::after {
      content: '';
      @include center;
      @include icon(40px);
    }

    &.disabled {
      background-color: lighten($arrow-bg, 1);
    }

    &:hover {
      background-color: darken($arrow-bg, 5);
    }
  }
}

.swiper-container {
  width: calc(100% - 120px);
  margin: 0 auto;

  .card {
    @include center;
    flex-direction: column;
    width: 170px;
    padding: 15px 15px 0;
    background: $white;
    border-radius: 12px;
    margin: 10px 0;
    user-select: none;
    cursor: pointer;

    img {
      @include icon(140px);
      border-radius: 50%;
      margin-bottom: 10px;
    }

    p {
      @include center;
      @include fonts(18px, 600);
      line-height: 140%;
      color: $black;
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.08);
    }
  }
}
