@import '../../../../assets/scss/mixins.scss';
@import '../../../../assets/scss/colors.scss';

.mainFooter {
  background: $main-footer-bg-color;
  padding: 60px 10px 30px;
  width: 100%;

  .mainFooterWrapper {
    width: 1180px;
    margin: 0 auto;
  }

  .mainFooterNavLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mainFooterNavigation {
    display: flex;
    gap: 30px;

    a {
      @include fonts(16px);
      line-height: 150%;
      color: $light-grey;

      &:hover {
        color: darken($light-grey, 10%);
      }
    }
  }

  .mainFooterSocialLinks {
    display: flex;
    gap: 20px;
  }

  .mainFooterText {
    margin-top: 30px;
    gap: 10px;

    p {
      @include fonts(14px, 300);
      color: $light-grey;
      margin: 0;
    }
  }
}
