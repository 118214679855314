$white: #fff;
$black: #000;
$light-grey: #e0e0e0;
$dark-grey: #a4a4a4;

$main-link-color: #6b75a8;
$main-primary-color: #6b75a8;
$main-small-text-color: #2e333a;
$main-regular-text-color: #221d1b;

$main-footer-bg-color: #2e333a;
$main-beauty-business-bg-color: #fafafa;

$sign-in-gray-color: #A9AEB6;
$sign-in-link-color: #1890FF;

$arrow-bg: #F5F5F5;
$login-btn-bg: #F5F5F5;
$login-label-text: #000000D9;
$login-active-border-color: #40a9ff;
