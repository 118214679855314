@import '../../assets/scss/colors.scss';
@import '../../assets/scss/mixins.scss';

.signInBtn {
  @include icon(50px);
  border-radius: 50%;
  border: 0;
  background: $login-btn-bg;
  cursor: pointer;

  &:hover {
    background: darken($login-btn-bg, 5%);
  }

  img {
    @include icon(26px);
  }
}
