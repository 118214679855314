@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin border($borderColor, $brad: 0) {
  border: 1px solid $borderColor;
  border-radius: $brad;
}

@mixin fonts($size: 22px, $weight: 400, $family: 'Proxima Nova') {
  font-size: $size;
  font-weight: $weight;
  font-family: $family, sans-serif;
}

@mixin button($height, $color, $bgColor, $borderColor) {
  @include border($borderColor, 100px);
  @include fonts(16px);
  line-height: 19px;
  padding: 14px 38px;
  height: $height;
  color: $color;
  background: $bgColor;
  cursor: pointer;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

@mixin icon($size) {
  width: $size;
  height: $size;
}
